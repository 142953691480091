/* CustomScrollbar.module.css */
:global(::-webkit-scrollbar) {
    width: 6px;  /* Thinner scrollbar */
    height: 6px; /* Thinner scrollbar */
}

:global(::-webkit-scrollbar-track) {
    background: transparent; /* No background color */
    border-radius: 10px; /* Rounded corners */
}

:global(::-webkit-scrollbar-thumb) {
    background: #8779d3;
    border-radius: 10px; /* Rounded corners */
}

:global(::-webkit-scrollbar-thumb:hover) {
    background: #8779d3;
}

:global(*) {
    scrollbar-width: thin;
    scrollbar-color: #8779d3 transparent; /* Thin scrollbar with transparent background */
}