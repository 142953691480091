/* header css */
.custom-date-range-picker .rs-picker-toggle {
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.22);
  border-radius: 4px;
}

.custom-date-range-picker .rs-stack {
  padding-top: 2px;
}

.custom-date-range-picker .rs-picker-toggle-caret.rs-icon {
  top: 4px !important;
}

.custom-date-range-picker .rs-picker-toggle-clean.rs-btn-close {
  top: 9px !important;
}

.rs-picker-daterange-panel {
  z-index: 100 !important;
}

.custom-date-range-picker .rs-picker-toggle-active {
  border: 2px solid #1976d2 !important;
  box-shadow: none;
}

/*.custom-date-range-picker .rs-picker-toggle:hover{*/
/*  border-color: #0e0e0e !important;*/
/*}*/

/*focussed border color #1976d2*/

.filter {
  display: flex;
  line-height: 26px;
  color: #fff;
  background-color: white;
  font-weight: bold;
  flex-direction: row;
  padding:16px 0px 8px 12px;
  /* justify-content: center; */
  z-index: 10;
  /* margin-bottom: 16px; */
  position: sticky;
  top: 0;
  border-bottom: 1px solid #e0e0e0;
}

.color-header-wrapper {
  display: flex;
}

.css-d9c359-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
  font-weight: 700 !important;
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  color: #2e2e2e;
  font-weight: normal;
  font-size: 13px;
  position: absolute;
  top: 100%;
  background-color: #fff;
  z-index: 10;
  max-height: 400px;
  overflow-y: auto;
  padding: 12px 12px 12px 0;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  min-width: 160px;
  list-style-type: none;
  scrollbar-width: thin;
    scrollbar-color: transparent transparent;
}

.dropdown-menu::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.dropdown-menu::-webkit-scrollbar-thumb {
  background-color: #888888;
  border-radius: 10px;
}

.dropdown-option {
  cursor: pointer;
  padding: 0 0 8px 14px;
}

.dropdown-option:hover {
  background-color: #f5f5f5;
  color: #2196f3;
}

@media only screen and (max-width: 1920px) {
  .filter {
    height: 64px;
  }
  .filter-label { 
    margin-top: 5px;
  }
}