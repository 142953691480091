.sign-in-container {
display: flex;
flex-direction: column;
align-items: center;
}

.sign-in-container .header-bar {
  height: 40px;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.sign-in-container .info-body {
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  padding-top: 80px;
}

.sign-in-container .footer-bar {
  display: flex;
    align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  background: #fff;
  border-top: 1px solid #f0f0f0;
}

.sign-in-container .logo-small {
  width: 24px;
  border-radius: 4px;
}

.info-body .company-title {
  font-style: italic;
  margin-bottom: 16px;
  color: #10175f;
  font-size: 48px;
  font-weight: 200;
}

.info-body .company-title-sup {
  font-style: italic;
  font-size: 12px;
  top: -20px;
}

.info-body .form-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}

.info-body .form-title {
  display: flex;
  justify-content: center;
  border-bottom: 2px solid #151e7a;
  padding: 12px 0 12px 0;
  font-size: 16px;
  font-weight: 700;
}

.info-body .form-item {
    display: flex;
    flex-direction: column;
    padding: 16px;
}

.info-body .form-option {
  display: flex;
  padding: 16px;
  justify-content: space-between;
}

.info-body .sign-btn {
  margin: 0 16px 8px 16px;
  display: flex;
  width: 92%;
  justify-content: center;
  padding: 8px 0;
  background-color: #151e7a;
  border-radius: 4px;
  color: white;
  font-weight: bold;
}

.justify-center {
  display: flex;
  justify-content: center;
}