.page-container {
    padding: 24px;
}

.section-headline {
    font-size: 16px;
}

.api-response-text {
    font-size: 14px;
    margin-top: 8px;
    background-color: #f6e49b;
    padding: 8px;
    border-radius: 4px;
    width: 400px;
}

.api-response-text.error {
    background-color: #f6a49b;
}

.api-response-text.success {
    background-color: #a9f6a4;
}

