.folder-tree-container {
    height: 400px; /* Adjust this value as needed */
    overflow-y: auto;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 16px 0 0 8px;
}

/* Add these new styles for a scrollbar */
.folder-tree-container::-webkit-scrollbar {
    width: 8px;
}

.folder-tree-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.folder-tree-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.folder-tree-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.folder-tree {
    font-family: Arial, sans-serif;
    padding: 10px;
}

.tree-node {
    padding-left: 20px;
}

.node-content {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px 0;
}

.node-content.folder {
    font-weight: bold;
}

.expand-icon {
    margin-right: 5px;
    font-size: 12px;
}

.node-icon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    display: inline-block;
}

.node-icon.folder::before {
    content: "📁";
}

.node-icon.file::before {
    content: "📄";
}

.node-name {
    font-size: 14px;
}

.node-children {
    margin-left: 20px;
}