.schedule-header {
    display: flex;
    align-items: center;
}

.api-files-header h2 {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
}

.refresh-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #6a5acd;
    display: flex;
    align-items: center;
    transition: transform 0.3s ease;
}

.refresh-button:hover {
    transform: rotate(180deg);
}

.refresh-button svg {
    font-size: 24px;
}