/* @import 'tailwindcss/base'; */
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import "rsuite/dist/rsuite.min.css";

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff !important;
  color: #2e2e2e !important;
  font-size: 13px !important;
  font-family: 'Roboto', sans-serif;
}

