.event-chart-full-container {
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #e7e7e7;
    overflow: hidden;
    justify-content: center;
    /*align-items: center;
    align-content: center; */
    display: flex;
    width: 100%;
    height: 400px;
    padding-top: 16px;
}

.daily-report-full-container {
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #e7e7e7;
    overflow: hidden;
    justify-content: center;
    /*align-items: center;
    align-content: center; */
    display: flex;
    width: 100%;
    height: 450px;
}

.filtername-container {
    display: none;
    transition: max-height 2s cubic-bezier(0, 1, 0, 1);
    -webkit-transition: max-height 2s cubic-bezier(0, 1, 0, 1);
    -moz-transition: max-height 2s cubic-bezier(0, 1, 0, 1);
    -o-transition: max-height 2s cubic-bezier(0, 1, 0, 1);
    -ms-transition: max-height 2s cubic-bezier(0, 1, 0, 1);
    font-size: 16px;
    align-items: center;
    max-height: 0;
    padding: 8px 0 8px 32px;
    margin-bottom: 8px;
    background-color: #f5f5f5;
    overflow: hidden;
    transition: max-height 0.15s ease-out;
}

.filtername-container.show {
    max-height: 1000px;
    display: flex;
}

.word-chart-full-container {
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #e7e7e7;
    overflow: hidden;
    justify-content: center;
    /* align-items: center;
    align-content: center; */
    display: flex;
    width: 100%;
    height: 300px;
    cursor: pointer;
    margin-bottom: 8px;
}

