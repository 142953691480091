.user-details-container {
    background-color: #ffffff; /* Optional: Set a background color */
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    0 4px 8px 0 rgba(0, 0, 0, 0.1); /* Shadow on all four sides */
}

.user-details-navbar {
    border-bottom: #cccccc solid 1px;
}

.user-info-header {
    background-color: #6a5acd;
    color: white;
    padding: 20px;
    text-align: center;
}

.user-info-header h2 {
    margin: 0 0 10px 0;
}

.user-info-header p {
    margin: 5px 0;
}

.user-nav {
    display: flex;
    background-color: #e6e3ff;
    padding: 10px;
}

.nav-button {
    background-color: #d1ccff;
    border: none;
    padding: 10px 20px;
    margin: 0 5px;
    cursor: pointer;
    font-weight: bold;
    transition: all 0.3s ease;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
}

.nav-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 100%);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.nav-button:hover::before {
    opacity: 1;
}

.nav-button.active {
    background-color: #6a5acd;
    color: white;
    transform: translateY(-3px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.nav-button.active::after {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 0;
    right: 0;
    height: 3px;
    background-color: #4a3a9d;
}

.content-container {
    padding: 20px;
    background-color: white;
}

.content-section {
    background-color: #f9f9f9;
    border-radius: 5px;
    padding: 20px;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #333;
}

/* Animation for content change */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.content-section {
    animation: fadeIn 0.5s ease;
}