.ticker-chart-container {
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #e7e7e7;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    align-content: center;
    display: flex;
    height: 400px;
    width: 100%;
    margin-bottom: 16px;
}

.event-chart-container {
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #e7e7e7;
    overflow: hidden;
    justify-content: center;
        /* align-items: center;
    align-content: center; */
    display: flex;
    width: 50%;
    height: 450px;
    cursor: pointer;
    margin-bottom: 8px;
}

.word-chart-container {
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #e7e7e7;
    overflow: hidden;
    justify-content: center;
       /*  align-items: center;
    align-content: center; */
    display: flex;
    width: 50%;
    margin-left: 0.5rem;
    height: 450px;
    cursor: pointer;
    margin-bottom: 8px;

}

.top-table-wrapper {
    width: 50%;
    max-height: 400px;
    display: flex;
    margin-bottom: 8px;
}

.table-title-bar {
    background-color: #fff;
    padding: 8px 0 8px 16px;
    background-color: #fff;
    /* border-radius: 16px 16px 0 0; */
    border-top: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
    border-left: 1px solid #e7e7e7;
    /* justify-content: center; */
    align-items: center;
    align-content: center;
    display: flex;
    font-size: 18px;
    color: rgb(51, 51, 51);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.universe-container {
    display: flex;
    flex-direction: column;
    padding: 0 12px 0 12px;
}

.rigth-container {
    display: flex;
    flex-direction: column;
    width: calc(100% - 250px);
    /* padding: 0 12px 0 12px;  */
    /* justify-content: center; */
}

.rigth-container.collapsed {
    width: calc(100% - 90px);
}

@media only screen and (max-width: 1920px) {
    .rigth-container {
        /*width: 87%;*/
    }
}