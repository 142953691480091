.sidebar {
    transition: width 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    user-select: none;
    width: 250px;
    height: 100vh;
    position: sticky;
    background: #eeeff3;
    top: 0;
    -webkit-box-shadow: inset 0px 0px 8px 0px rgba(0, 0, 0, 0.19);
    -moz-box-shadow: inset 0px 0px 8px 0px rgba(0, 0, 0, 0.19);
    box-shadow: inset 0px 0px 8px 0px rgba(0, 0, 0, 0.19);
}

.sidebar.collapsed {
    width: 90px;
}

.collapse-arrow {
    /*position: absolute;*/
    /*top: 73px;*/
    /*right: -11px;*/
    z-index: 20;
    rotate: -90deg;
}

.sidebar.collapsed .collapse-arrow {
    rotate: 90deg;
}


.logo-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    height: 52px;
    cursor: pointer;
}

.filter-label {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 13px;
    /* make smoothing antialiased */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-top: 24px;
}

.filter-label-collapsed {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
    /* make smoothing antialiased */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #212121;
}

.logo {
    width: 80%;
}

.logo-small {
    width: 46px;
    border-radius: 4px;
}

.accordion-header {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-bottom: 20px;
    padding-top: 30px;
    font-size: 16px;
    justify-content: center;
    justify-items: center;
}

.settings-icon {
    width: 28px;
    height: 28px;
}

.dropdown-container {
    position: absolute;
    bottom: 50px;
    margin-left: 8px;
    width: 93%;
}

.sidebar.collapsed .dropdown-container {
    width: 48%;
}

.dropdown-container a {
    text-decoration: none;
}

.dropdown-container a:hover {
    color: white;
}

.profile-container {
    display: flex;
    margin-bottom: 20px;
    margin-top: 20px;
    align-items: center;
    flex-direction: column;
}

.profile-container-expanded {
    display: flex;
    margin-bottom: 20px;
    margin-top: 20px;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 16px;
}

.expand-right-ic-container {
    background: #d9def2;
    border-radius: 50%;
    padding: 12px;
    cursor: pointer;
}

.expand-right-ic {
    width: 25px;
    height: 25px;
}

.expand-right-ic-expanded {
    width: 25px;
    height: 25px;
    rotate: 180deg;
}

.profile-ic-container {
    display: flex;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 2px solid #d9def2;
}

.profile-ic-container-expanded {
    display: flex;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 2px solid #d9def2;
    /* padding-left: 20px; */
    align-items: center;
    /*text-overflow: ellipsis;*/
    width: 82%;
}

.profile-ic {
    display: flex;
    width: 45px;
    height: 45px;
    justify-content: center;
    align-items: center;
    background: #10175f;
    border-radius: 50%;
    color: white;
    font-weight: bold;
    font-size: 16px;
}

.profile-ic-expanded {
    display: flex;
    width: 45px;
    height: 45px;
    justify-content: center;
    align-items: center;
    background: #10175f;
    border-radius: 50%;
    color: white;
    font-weight: bold;
    font-size: 16px;
    margin-right: 3px;
}

.profile-name {
    max-width: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.ent-section {
    display: flex;
    flex-direction: column;
    padding-left: 16px;
}

.ent-option {
    display: flex;
    align-items: center;
}

.ent-title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}

.option-line {
    border-top: 1px solid #d9def2;
    width: 92%;
    margin-top: 5px;
    margin-bottom: 5px;
}

.select-all-link {
    font-size: 11px;
    color: #3843b0;
    text-transform: uppercase;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 5px
}


@media only screen and (max-width: 1920px) {

    .filter-label {
        margin-top: 15px;
        font-size: 12px;
    }

    .settings-text {
        font-size: 18px
    }

    .settings-icon {
        width: 51px;
        height: 30px;
    }

    .collapse-arrow {
        top: 54px;
    }

    .dropdown-container {
        left: 14px;
        bottom: 57px
    }
}