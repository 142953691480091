.users-container {
    display: flex;
    height: 88vh;
    background-color: #f0f0f0;
    border-radius: 8px;
    overflow: hidden;
}

.left-section {
    width: 25%;
    background-color: #e6e3ff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.right-section {
    width: 80%;
    padding: 0 20px;
    background-color: #ffffff;
    overflow-y: auto;
}

.create-user-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #6a5acd;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 20px;
}

.create-user-btn:hover {
    background-color: #5a4abf;
}

.create-user-btn svg {
    margin-right: 8px;
}

.search-box {
    position: relative;
    margin-bottom: 20px;
}

.search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #6a5acd;
}

.search-box input {
    width: 100%;
    padding: 10px 10px 10px 35px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: box-shadow 0.3s ease;
}

.search-box input:focus {
    outline: none;
    box-shadow: 0 0 5px #6a5acd;
}

.user-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    overflow-x: hidden;
}

.user-item {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 4px;
}

.user-item:hover, .user-item.active {
    background-color: #d1ccff;
}

.create-user-form {
    display: flex;
    flex-direction: column;
}

.create-user-form input {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: box-shadow 0.3s ease;
}

.create-user-form input:focus {
    outline: none;
    box-shadow: 0 0 5px #6a5acd;
}

.submit-btn {
    padding: 10px;
    background-color: #6a5acd;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-btn:hover {
    background-color: #5a4abf;
}

.user-details, .no-selection {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 4px;
}

/* Animations */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.right-section > * {
    animation: fadeIn 0.5s ease;
}