.company-page-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.cjjWdp {
    background: white;
}

.info-container {
    display: flex;
    font-size: 16px;
    align-items: center;
    padding: 8px 0 8px 20px;
    margin-bottom: 8px;
    text-transform: uppercase;
    background-color: #f5f5f5;
    overflow: hidden;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    border-radius: 3px;
    position: sticky;
    top: 0;
    z-index: 7;
}

.info-container .company-name {
    font-size: 24px;
    margin-right: 32px;
}

.cmp-left-container {
    align-items: center;
    flex-direction: column;
    padding: 0px 8px 180px 0;
    width: 100%;
    height: 100%;
}

.cmp-left-container::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #fff;
}

.cmp-left-container::-webkit-scrollbar-thumb {
    /* background-color: #888888; */
    border-radius: 10px;
}

.cmp-chart-container {
    align-items: center;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: inherit;
    -ms-overflow-style: none;
    height: calc(100vh - 200px);
}

.cmp-right-container {
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 140px);
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: #888888;
    -ms-overflow-style: none;
    padding: 0 0 0 8px;
    transition: width cubic-bezier(0.4, 0, 0.2, 1) .2s;
    color: #2e2e2e;
}

.cmp-right-container::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #fff;
}

.cmp-right-container::-webkit-scrollbar-thumb {
    background-color: #888888;
    border-radius: 10px;
}

.cmp-right-container.collapsed {
    width: 80%;
}

.row-header {
    position: sticky;
    top: 0;
    background-color: #fff;
}


.body-row.positive {
    background-color: #e8f5e9;
}

.body-row.negative {
    background-color: #ffebee;
}


.row-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.row-container.selected {
    border: 1px solid #283593;
    border-radius: 2px;
}

.table-row {
    display: flex;
    width: 100%;
    cursor: pointer;
    padding: 4px 4px 4px 16px;
    /* border-bottom: 1px solid #e2e2e2; */
}
.table-row.positive {
    background-color: #81c784;
}
.table-row.negative {
    background-color: #ff8a80;
}

.table-row:hover {
    background-color: #e0e0e0;
}
.table-row.positive:hover {
    background-color: #4caf50;
}
.table-row.negative:hover {
    background-color: #ef5350;
}


.table-row.expanded {
    background-color: #f5f5f5;
}
.table-row.positive.expanded {
    background-color: #81c784;
}
.table-row.negative.expanded {
    background-color: #ff8a80;
}

.table-row.positive.expanded td:first-child::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0px;
    width: 4px;
    background-color: #43a047;
}
.table-row.negative.expanded td:first-child::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0px;
    width: 4px;
    background-color: #d50000;
}
.table-row.expanded td:first-child::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0px;
    width: 4px;
    background-color: #f5f5f5;
}

.metadata-col1.positive {
    padding-top: 10px;
}

.metadata-col1.positive::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0px;
    width: 4px;
    background-color: #43a047;
}


.metadata-col1.negative::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0px;
    width: 4px;
    background-color: #d50000;
}


.metadata-col1::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0px;
    width: 4px;
    background-color: #cecece;
}

.header-container {
    display: flex;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #f1f1f1;
    align-items: center;
    height: 30px;
    border-radius: 3px;
}

.body-row {
    /* max-height: 200px; */
    overflow-y: auto;
    display: flex;
    width: 100%;
    cursor: pointer;
    padding: 4px 4px 4px 16px;
    border-bottom: 1px solid #e2e2e2;
    position: relative;
}

.body-content {
    max-height: 300px;
    overflow-y: scroll;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
}

.body-content:hover,
.body-content:focus {
    scrollbar-color: #888888 #f0f0f0;
}

.body-content::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.body-content::-webkit-scrollbar-thumb {
    background-color: #888888;
    border-radius: 10px;
}

.time-header-cell {
    align-items: center;
    width: 200px;
    display: flex;
    padding: 5px 8px 5px 13px;
}

.content-header-cell {
    padding: 3px 8px 3px 8px;
}

.expand-arrow {
    margin-right: 8px;
    cursor: pointer;
    border-radius: 50%;
    color: grey;
}

.expand-arrow.expanded {
    transform: rotate(90deg);
}

.expand-arrow:hover {
    background-color: #f1f1f1;
}

.overflow-div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.events-list-container {
    overflow: visible;
    white-space: initial;
}

.event-name-chip {
    display: inline-block;
    padding: 0 10px;
    font-size: 12px;
    line-height: 24px;
    border-radius: 12px;
    background-color: #e0e0e0;
    color: #333;
    margin-left: 3px;
    /* margin-right: 8px; */
    /* margin-bottom: 2px; */
    cursor: pointer;
}

.event-name-chip.positive {
    background-color: #43a047;
    color: #fff;
}

.event-name-chip.negative {
    background-color: #d50000;
    color: #fff;
}

.edit-icon {
    position: absolute;
    top: 15px;
    left: 155px;
    cursor: pointer;
    border-radius: 50%;
    color: grey;
}

.edit-icon:hover {
    background-color: #f1f1f1;
}

/* pie chart css ----------------------- */
.pie-chart {
    width: 100px;
    height: 70px;
    background-color: #f1f1f1;
}

.popover-container {
    display: flex;
    position: absolute;
    background-color: #e8eaf6;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    padding: 0.5rem;
    width: 40px;
    height: 40px;
    transition: width 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.popover-container.open {
    border-radius: 20px;
    width: 400px;
    border: 1px solid #3f51b5;
}

.full-popover {
    padding: 0 8px 0 8px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    align-items: center;
}

.full-popover .css-1rpyo6o-MuiInputBase-root-MuiInput-root:after {
    border-bottom: 1px solid #9fa8da;
}

@media only screen and (max-width: 1920px) {
    .info-container {
        font-size: 12px;
        margin-bottom: 8px;
        overflow: hidden;
    }

    .info-container .company-name {
        font-size: 18px;
        margin-right: 20px;
    }

    .cmp-right-container {
        height: calc(100vh - 120px);
    }
}

.box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #ccc;
    border: 1px solid black;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;
    margin: 20px;
}

.react-resizable {
    position: relative;
}
.react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
    background-position: bottom right;
    padding: 0 3px 3px 0;
}
.react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
    transform: rotate(90deg);
}
.react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize;
}
.react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
    transform: rotate(180deg);
}
.react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
    transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
    top: 50%;
    margin-top: -10px;
    cursor: ew-resize;
}
.react-resizable-handle-w {
    left: 0;
    transform: rotate(135deg);
}
.react-resizable-handle-e {
    right: 0;
    transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
    left: 50%;
    margin-left: -10px;
    cursor: ns-resize;
}
.react-resizable-handle-n {
    top: 0;
    transform: rotate(225deg);
}
.react-resizable-handle-s {
    bottom: 0;
    transform: rotate(45deg);
}