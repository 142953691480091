.auth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 8px;
    width: 300px;
    margin: 0 auto;
}

.auth-container input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.auth-button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #6a5acd;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.auth-button:hover:not(:disabled) {
    background-color: #5a4abf;
}

.auth-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.auth-button.loading {
    background-color: #6a5acd;
}

.auth-button.success {
    background-color: #4CAF50;
}

.auth-button.error {
    background-color: #f44336;
}