.main-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.sidebar2 {
    width: 200px;
    background-color: #f0f0f0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.company-icon {
    margin-bottom: 40px;
}

.sidebar2 nav {
    width: 100%;
}

.sidebar2 nav ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebar2 nav ul li {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.sidebar2 nav ul li:hover {
        background-color: #6a5acd; /* Blue-purplish color */
}

.sidebar2 nav ul li span {
    margin-top: 5px;
    font-size: 14px;
}

.content {
    flex-grow: 1;
    padding: 20px;
}