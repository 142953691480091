.modal {
  display: none;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(126, 126, 126, 0.562);
  cursor: not-allowed;
}

.modal.visible {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  border: 0px solid #888;
  border-radius: 4px;
  width: 600px;
  height: auto;
  max-height: 600px;
  /* -webkit-box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.19);
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.19); */
  padding: 20px;
  font-size: 15px;
  cursor: default;
  text-decoration: none;
}

.edit-event-row {
  display: flex;
  width: 100%;

}

.edit-event-row .col1 {
  width: 40%;
  margin-left: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.edit-event-row .col2 {
  display: flex;
  width: 15%;
  justify-content: space-between;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  background-color: #ebebeb;
  padding: 8px;
  height: 50px;
}

.modal-actions {
  display: flex;
  padding-right: 16px;
  cursor: pointer
}

.metadata-container {
  width: 25%;
  overflow-y: auto;
  height: calc(100vh - 140px);
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #888888;
  -ms-overflow-style: none;
  padding-left: 4px;
}

.metadata-container ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #fff;
}

.metadata-container ::-webkit-scrollbar-thumb {
  /* background-color: #888888; */
  border-radius: 10px;
}

.metadata-content {
  padding: 8px;
}

.metadata-value {
  white-space: normal;
  /* text-overflow: ellipsis; */
  /* overflow: hidden; */
  /* white-space: nowrap; */
  /* width: 175px; */
}

.editbox-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
  padding: 8px 0 5px 8px;
  border: 1px solid #e2e2e2;
  position: relative;
}

.editbox-label {
  position: absolute;
  top: -10px;
  left: 5px;
  background-color: white;
  padding: 0 5px
}

.editbox-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: 'nowrap';
}

.phrase-container {
  display: flex;
  flex-direction: column;
  margin: 4px 4px 4px 0;
  padding: 8px 0 5px 8px;
  border: 1px solid #e2e2e2;
  position: relative;
}

@media only screen and (max-width: 1920px) {
  .metadata-container {
    height: calc(100vh - 120px);
    width: 30%;
  }
}